/* Start */
.raffleId20 {
  border-color: var(--iq-orange) !important;
}

.raffleId20 .winnerDataWrap div.winnerDataInner {
  border-color: var(--iq-pink) !important;
}
.previous-drawn__section {
  margin-top: 50px;
}
/* End */
