.qr_reader {
  width: 100%;
  height: 50vh;
  margin: 0 auto;
  position: relative;
}

.qr_reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr_reader .qr_box {
  width: 100% !important;
  left: 0 !important;
}

.qr_reader .qr_frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr_reader {
    width: 100%;
  }
}
