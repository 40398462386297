/* styles */
/* test from css */
/* @tailwind base; */
/* @tailwind components; */
@tailwind utilities;

#layout-content {
  padding: 0 7px;
}

#dnone {
  display: none;
}

body {
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0;
}

.padded {
  padding: 0 20px;
}

.textwrapper {
  margin-bottom: 0px;
}

.page_winners .pagebanner_content h2 p,
.page_winners h1.page-title p {
  margin: 0;
}

.page_play-responsibly .pagebanner_content p {
  font-size: 20px;
  margin-top: 0;
}

.page_play-responsibly .pagebanner_content h2 {
  margin: 5px 0px;
  max-width: 350px;
}

.page_play-responsibly .pagebanner_content {
  top: 30%;
}

.textblock strong,
.videos_heading p strong {
  font-size: 28px !important;
  margin-top: 30px;
  font-family: "product-sans-bold";
}
.page-rtl .textblock strong {
  font-family: "swissra-bold";
}

.textblock a {
  transition: 0.2s ease-in-out;
  color: var(--iq-pink);
}

.textblock a:hover {
  color: var(--iq-orange);
}

.btn--terciary {
  border-color: transparent;
  background-color: var(--iq-white);
  height: 48px !important;
  color: var(--iq-black);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.btn--terciary p {
  margin-top: 5px;
  font-size: 17px;
}

.textblock strong {
  margin-bottom: 20px;
}

.pagebanner {
  margin: 20px 0 30px;
  position: relative;
}

.img_Size {
  height: auto;
}

.winner_text {
  font-size: 28px !important;
  color: var(--iq-black);
}

h1.page-title {
  font-size: 55px;
  margin: 0;
}

h2.page-sub-title,
.textblock h2,
.textblock h1 {
  font-size: 28px !important;
  color: var(--iq-black);
  margin: 0.5rem 0px;
  font-family: "Product-Sans-Bold";
}
.page-faq .pageheader p,
.pageheader,
.pageheader p {
  font-size: 28px !important;
}
.page-rtl h2.page-sub-title,
.page-rtl .textblock h2,
.page-rtl .textblock h1 {
  font-family: "swissra-bold";
}

.pagebanner_content p strong {
  line-height: 28px;
}

.page_liveloto-draw .pagebanner_content h1 {
  margin: 0;
}

.page_liveloto-draw .pagebanner_content h2 p,
.page_liveloto-draw .pagebanner_content h1 p {
  font-size: 50px;
  color: var(--iq-white);
  margin: 0;
}

.page_liveloto-draw .btn--terciary:hover {
  color: var(--iq-black);
}

.page_community .pagebanner h1 p {
  margin: 0;
}

#pageoffers_mobile {
  display: none;
}

.pagebanner_content {
  position: absolute;
  top: 35%;
  left: 25px;
  color: var(--iq-white);
}

.pagebanner_content h2 {
  font-size: 43px;
  margin: 0;
}

.textblock {
  margin: 0px 0 20px 0;
}

.news_article p {
  font-size: 17px;
}

.page-rtl .arabicurr {
  display: flex;
  flex-direction: row-reverse;
}
.page-rtl .arabicurr > .digits-iq-bld:first-child {
  font-family: Swissra-Bold !important;
}
.news_article {
  padding-right: 40px;
}

.textblock h5 {
  font-size: 28px;
  color: var(--iq-darkblack);
  margin-bottom: 25px;
}

.textblock p {
  font-size: 17px;
  line-height: 33px;
  text-align: justify;
  margin-bottom: 10px;
}

.pageoffers {
  display: flex;
  margin: 50px 0 120px 0;
}

.pageoffers_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 325px;
}

.pageoffers_box img {
  margin-bottom: 35px;
  max-width: 150px;
}

.page_community .pageoffers .pageoffers_box {
  background-color: var(--iq-white);
  border-style: solid;
  border-width: 3px;
  border-color: var(--iq-orange);
  margin-right: 15px;
}
.page-rtl .page_community .pageoffers .pageoffers_box {
  margin: 0 0 0 15px;
}

.pageoffers .pageoffers_box a.btn--terciary {
  padding: 15px 0;
  border-radius: 0;
  color: var(--iq-pink);
}

.pageoffers .pageoffers_box a.btn--terciary:hover {
  background-color: var(--iq-white);
  border-color: var(--iq-white);
}

.pageoffers .pageoffers_box a.btn--terciary p {
  font-size: 17px;
}

.page_community .pageoffers .pageoffers_box:nth-child(2) {
  margin: 0 0 0 15px;
}
.page-rtl .page_community .pageoffers .pageoffers_box:nth-child(2) {
  margin: 0 15px 0 0;
}

.page_winners .pageoffers_box {
  background-color: #006ac2;
  width: 32%;
}

.page_winners .pageoffers_box .btn {
  width: 70%;
}

.page_winners .pageoffers_box:nth-child(1) {
  margin-right: 3%;
}

.page_winners .pageoffers_box:nth-child(2) {
  margin-left: 0;
}

.page_winners .pageoffers_box:nth-child(3) {
  margin-left: 3%;
}

.page_winners .pagebanner_content {
  top: 33%;
  left: 80px;
}

.video_date {
  margin-bottom: 20px;
  font-size: 17px;
}

.winners .pageheader,
.pageheader p {
  margin: 30px 0 30px 0;
}

.page_our-initiatives {
  margin-bottom: 145px;
}

.page_fraud-awareness .pagebanner_content {
  left: 70px;
}

.page_fraud-awareness .textblock {
  margin-bottom: 100px;
}

.page_liveloto-draw .pagebanner_content {
  left: 45px;
  top: 36%;
}

.page_liveloto-draw .pagebanner_content h2 {
  font-size: 55px;
  margin: 0;
}

.page_liveloto-draw .pagebanner_content p {
  font-size: 25px;
  color: var(--iq-yellow);
}

.page_liveloto-draw {
  margin-bottom: 221px;
}

.container_page_liveloto-draw {
  margin-top: 10px;
}

.page_draw .pagebanner_content {
  top: 50%;
  left: 50%;
  margin-top: -75px;
  margin-left: -150px;
}

.page_draw .pagebanner_content h1 {
  font-size: 115px;
  margin: 0;
}

.page_draw .pagebanner_content h1 p {
  margin: 0;
}

.draw_footer {
  display: flex;
  justify-content: space-between;
  margin: 50px 0px;
}

.draw_footer .draw_footer-box {
  max-height: 420px;
  padding: 30px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--iq-white);
  border-style: solid;
  border-width: 3px;
  border-color: var(--iq-orange);
}

.draw_footer .draw_footer-box:nth-child(2) {
  margin: 0px 15px;
}

.draw_footer .draw_footer-box a {
  border-radius: 0px;
  color: var(--iq-pink);
}

.draw_footer .draw_footer-box a p {
  margin-top: 0px;
  font-size: 17px;
}

.draw_footer .draw_footer-box:nth-child(3) {
  margin-right: 15px;
}
.page-rtl .draw_footer .draw_footer-box:nth-child(3) {
  margin-left: 15px;
  margin-right: 0;
}

.draw_footer-imgwrap {
  height: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.draw_page .draw_footer-imgwrap {
  height: 110px;
}
.draw_page.draw_footer a {
  color: var(--iq-black);
}

.page-faq,
.page-legal {
  width: 835px;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 115px;
}

.page-faq h2,
.page-faq h1 {
  margin: 65px 0 35px 0;
}

.accordeon button {
  border-radius: 5px;
  width: 100%;
  background-color: var(--iq-mildgrey);
  border: none;
  outline: none;
  text-align: left;
  display: flex;
  padding: 25px 25px 20px 25px;
  font-size: 17px;
}

.accordeon_box {
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--iq-pink);
  border: none;
  outline: none;
  text-align: left;
  display: flex;
  padding: 25px 25px 20px 25px;
  color: var(--iq-white);
  font-size: 17px;
  width: 100%;
}

.accordeon_box--header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
}
.accordeon_box .accordeon_box--header:after {
  content: url(../images/arrow-blue.svg);
  transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}
.accordeon_box.active .accordeon_box--header:after {
  content: url(../images/arrow-black.svg);
  transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  position: relative;
  top: -6px;
  left: 1px;
}

.accordeon_box--header p {
  display: block;
  font-size: 20px;
  margin-top: 0;
  color: var(--iq-black);
  line-height: 28px;
}
.page-rtl .accordeon_box--header p {
  text-align: justify;
}

.accordeon h6 {
  margin: 0;
  padding-right: 10px;
  line-height: 28px;
}

.page-rtl .accordeon_footer p {
  text-align: right;
}

.accordeon_footer p,
.accordeon_footer ul,
.accordeon_footer ol,
.accordeon_footer table {
  display: none;
}

.accordeon_footer.show p,
.accordeon p.show {
  display: block;
}
.regular-iq {
  font-family: "Product-Sans-Regular" !important;
}
.accordeon table td {
  padding: 5px 10px;
}

.accordeon_footer.show ul,
.accordeon_footer.show ol {
  display: block;
  color: var(--iq-brown);
}
.page-rtl .accordeon_footer ul li {
  text-align: right;
}
.accordeon_footer.show table {
  display: table;
}

.accordeon_footer p {
  color: var(--iq-brown);
  font-size: 16px;
  margin-top: 10px;
  max-width: 670px;
  line-height: 28px;
  transition: 0.2s ease-in-out;
}
.accordeon_box.active {
  background-color: var(--iq-white);
  border: 1.5px solid var(--iq-dullgrey);
  border-radius: 5px;
  flex-direction: column;
}

.accordeon_box.active p strong {
  color: var(--iq-brown) !important;
}

.page-contact {
  max-width: 805px;
  margin: 0 auto;
  margin: 85px auto 115px;
  padding: 0 20px;
}

.page-contact--header h2 {
  text-align: center;
  margin: 55px 0 35px;
}

.page-contact--header p {
  font-size: 17px;
  margin: 18px 0 35px 0;
}

.page-contact .community-guidelines {
  margin-top: 40px;
}

.page-contact .community-guidelines p {
  text-align: center;
  color: var(--iq-black);
}

.page_our-initiatives .pagebanner_content h1 p {
  font-size: 50px;
  margin: 0;
}

.page_our-initiatives .pagebanner_content {
  top: 35%;
}

.page_our-initiatives .pagebanner_content p {
  font-size: 20px;
  margin-top: 0;
}

.page_our-initiatives .pagebanner_content h1 {
  margin: 0;
}

.contact_boxes {
  display: flex;
}

.contact_boxes .contactbox {
  width: 50%;
  padding: 15px 0;
  background-color: var(--iq-orange);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.contact_boxes .contactbox img {
  margin-right: 10px;
}

.contact_boxes .contactbox p {
  margin-top: 0;
  position: relative;
  top: 3px;
}

.contact_boxes .contactbox:nth-child(1) {
  margin-right: 6px;
}

.page-contact .contact_boxes .contactbox p a {
  color: var(--iq-darkblack);
  margin: 0 0 0 5px;
  font-size: 17px;
}

.page_latest-news {
  margin: 20px 0 50px 0;
}

.page_latest-news h2 {
  justify-content: center;
  display: flex;
  align-items: center;
}

.page_latest-news .news_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.page_latest-news .pageheader_heading h2 p {
  font-size: 50px;
}

.page_fraud-awareness .pagebanner_content h1 {
  margin: 0;
}

.page_fraud-awareness .pagebanner_content h1 p {
  font-size: 55px;
  max-width: 300px;
  margin-top: 0;
}

.page_fraud-awareness .pagebanner_content {
  top: 32%;
  left: 40px;
}

.page-contact .social_list {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.page-contact .social_list p {
  margin-bottom: 10px;
}

.page-contact .social_list li {
  width: 25%;
}

.page-contact .social_list li:not(:last-child) {
  margin-right: 5px;
}

.page-contact .social_list li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  background-color: #f1f1f1;
  padding: 20px 10px;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
}

.page-contact .social_list li a:hover {
  color: var(--iq-white);
}

.page-contact .social_list li img {
  width: 38px;
  margin: 0 auto;
}

.page-contact .social_list p {
  text-align: center;
  font-size: 17px;
  max-width: 100px;
  margin: 0px auto 10px;
}

.page-legal h2 {
  margin: 65px 0 35px 0;
}

.page_about-us {
  margin-bottom: 80px;
}

.page_about-us .pagebanner_content h2 {
  margin: 0;
}

.page_about-us h2 p {
  font-size: 55px;
}

.page_about-us .pagebanner_content p {
  margin: 0;
}

.page_about-us .pagebanner_content {
  margin-left: 33px;
}

.page_about-us .videos_wrapper--slide {
  display: flex;
  justify-content: space-between;
  width: 370px;
}

.page_about-us .videos_wrapper .about_video {
  background: rgba(0, 119, 200, 0.12);
  padding: 20px;
  border: none;
  display: block;
}

.page_about-us .videos {
  background: var(--iq-orange);
  padding: 35px 65px 60px 65px;
  margin-top: 70px;
}

.page_about-us .videos_heading p {
  font-size: 27px;
  margin-bottom: 15px;
}
.page_about-us .textblock ul li strong,
.page_about-us .textblock ul li {
  font-size: 17px !important;
}

.news_row {
  display: flex;
  margin-top: 40px;
}

.news_article {
  margin-bottom: 40px;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 15px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.news_article--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.news_article span {
  color: var(--iq-orange);
  font-size: 12px;
  margin: 10px 0 5px;
  border-bottom: 1px solid var(--iq-orange);
  padding-bottom: 5px;
  display: block;
  line-height: normal;
}

.news_article a {
  color: var(--iq-orange);
  font-size: 13px;
  margin: 5px 0px;
  display: block;
  line-height: 20px;
  transition: 0.2s;
}

.news_article a:hover {
  color: var(--iq-black);
}

.news_article p {
  color: var(--iq-darkgrey);
  line-height: 28px;
}

.news_article--footer {
  display: flex;
}

.news_article--footer a:first-child {
  background-color: transparent;
  color: var(--iq-orange);
  padding: 0;
  transition: 0.2s ease-in-out;
  margin: 5px 0 0 0;
  display: block;
  line-height: normal;
  font-size: 12px;
  border-bottom: 1px solid var(--iq-orange);
}

.news_article--footer a:first-child:hover {
  color: var(--iq-black);
  border-color: var(--iq-black);
}

.videos_wrapper-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 35px;
}

.page-past-draw-shows {
  margin: 60px 0 100px 0;
}

.page-past-draw-shows .videos {
  margin-top: 30px;
}

.page_winners-stories {
  margin: 0 0 50px 0;
}

.page_winners-stories .pageheader {
  margin-bottom: 40px;
}

.page-past-draw-shows .videos_wrapper {
  display: flex;
  flex-direction: column;
}

.page-past-draw-shows .videowrap p {
  margin-bottom: 10px;
}

.page_withdraw .pagebanner_content {
  margin-left: 40px;
  top: 38%;
}

.process_row {
  display: flex;
  margin-bottom: 50px;
  margin-top: 50px;
}

.process_box:not(:last-child) {
  margin-right: 15px;
}

.process_box_htp:not(:last-child) {
  margin-right: 15px;
}

.videos_bg {
  background: var(--iq-orange);
  padding: 60px;
  margin-bottom: 100px;
}

.videos_wrap {
  display: flex;
  flex-wrap: wrap;
}

.videos_wrap--content {
  margin: 0 0 25px 0;
  width: 33%;
  text-align: center;
}

.videos_wrap--content:nth-child(3n) {
  margin-right: 0;
}

.videos_wrap:not(:last-child) {
  margin-bottom: 40px;
}

.videos_wrap .video iframe {
  border: none;
  background: var(--iq-pink);
}

.videos_wrap .video_footer h3 {
  text-align: center;
  margin: 20px 0 10px 0;
  font-size: 19px;
}

.videos_wrap .video_footer p {
  text-align: center;
  color: var(--iq-darkgrey);
}

.process .process_box .step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  margin: 0 auto;
  height: 75px;
  position: absolute;
  top: -45px;
  color: var(--iq-white);
  font-size: 33px;
  transform: translate(-50%, 0);
  left: 50%;
  border-radius: 50%;
}

.page_liveloto-draw .pagebanner {
  margin: 70px 0 15px 0;
}

.process_box p {
  color: var(--iq-brown);
  margin-top: 12px;
  line-height: 27px;
  text-align: justify;
}

.process_box_htp p {
  color: var(--iq-white);
  margin-top: 12px;
  line-height: 27px;
  text-align: justify;
}

.winners .winners_winner {
  margin: 0 12px 35px 0;
}

.winners .winners_winner:nth-child(4n) {
  margin-right: 0;
}

.winners .winners_winner img {
  width: 300px;
  max-width: 100%;
}

.draw_mobile_slider .draw_slider_wrap .box {
  border: 2px solid var(--iq-orange);
  background-color: var(--iq-white);
  width: 100%;
  max-width: 100%;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.draw_slider_wrap #draw-slide-stream a {
  transition: 0.2s ease-in-out;
}

.draw_mobile_slider .draw_slider_wrap .box a {
  height: 30px !important;
  background-color: var(--iq-white);
  display: flex;
  align-items: center;
  padding: 0 15px;
  transition: 0.2s ease-in-out;
  color: var(--iq-orange);
  width: 80%;
}

.draw_mobile_slider .draw_slider_wrap .box a:hover {
  background-color: var(--iq-black);
  color: var(--iq-white);
}

.winners .winners_winner h3 {
  color: var(--iq-pink);
  font-size: 20px;
  margin: 20px 0 6px 0;
}

.winners {
  display: flex;
  flex-wrap: wrap;
}

.winners .winners_winner p {
  font-size: 17px;
  color: var(--iq-darkgrey);
  max-width: 180px;
  line-height: 26px;
  margin: 0;
}

.winners .winners_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}

.page_withdraw,
.page_how-play,
.page_buycredit {
  margin-bottom: 130px;
}

.page_withdraw,
.page_how-play,
.page_buycredit .pagebanner {
  margin-bottom: 100px;
}

.page_how-play .pagebanner_content {
  top: 41%;
  left: 70px;
}

.page_buycredit .pagebanner_content {
  top: 40%;
  left: 65px;
}

.page-faq .accordeon_footer span a {
  color: var(--iq-pink);
}

#pageoffers_mobile {
  margin: 30px 0 40px 0;
}

#pageoffers_mobile .pageoffers_box {
  padding: 20px 0;
  height: 300px;
  max-height: 100%;
  width: 100%;
}

#pageoffers_mobile .pageoffers_box img {
  width: 110px;
  margin: 25px 0 40px 0;
}

#pageoffers_mobile .pageoffers_box:last-child {
  margin-left: 0;
}

#pageoffers_mobile .pageoffers_box:first-child {
  margin-bottom: 20px;
}

#play_responsibly-mobile,
#fraud-awareness-mobile,
#live_draw-mobile,
#about_mobile,
.howto_video_popup,
.draw_mobile_slider,
#buy-credit-mobile {
  display: none;
}

.howto_page .pagebanner_subheading p {
  margin-top: 10px;
  font-size: 18px;
}

.howto_page .pagebanner_content h2 p {
  font-size: 20px;
}

.howto_page .pagebanner_content {
  top: 35%;
  left: 70px;
}

.howto_page .pagebanner_content p strong {
  line-height: 35px;
  font-size: 50px;
}

.page_our-initiatives .subheading p {
  max-width: 225px;
}

.howto_page .pagebanner_content h2 p,
.howto_page h1.page-title p {
  margin-top: 10px;
}

.howto_video_popup.active {
  display: block;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 48px 35px 35px 35px;
  width: 545px;
  max-width: 100%;
  background: var(--iq-white);
  border-radius: 5px;
  box-shadow: 0px 0px 35px #00000021;
}

.howto_video_popup.active img {
  float: right;
  position: relative;
  top: -24px;
  left: 15px;
}

.howto_video_popup.active iframe {
  width: 100%;
  border: none;
}

#withdraw_mobile {
  display: none;
}

#how-play-mobile {
  display: none;
}

.howto_page.play .pagebanner_content {
  top: 40%;
}

.howto_page.credit .pagebanner_content {
  top: 40%;
}

.videos_wrap .video_footer p {
  font-size: 17px;
}

.page_about-us .pagebanner_subheading p {
  font-size: 20px;
}

.videos_wrapper--btn {
  display: none;
}

.news_detail .pageheader p {
  max-width: 425px;
  margin: 0 auto;
  line-height: 44px;
  margin-top: 70px;
  text-align: center;
  font-family: "Product-Sans-Bold";
}

.news_mainimg img {
  width: 620px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.news_detail .textwrapper {
  padding: 0 65px;
}

.news_detail .textblock strong {
  font-size: 19px !important;
}

.news_detail .share_tab {
  display: flex;
  align-items: center;
  margin: 50px 0 75px 0;
  padding: 0;
  list-style-type: none;
}

.news_detail .share_tab img {
  margin-left: 5px;
}

.howto_page.credit .process_box {
  background-color: var(--iq-white);
  border: solid 3px var(--iq-orange);
}

.howto_page.credit .process_box .step {
  background-color: var(--iq-orange);
}

.howto_page.withdraw .process_box {
  background-color: var(--iq-white);
  border: solid 3px var(--iq-orange);
}

.howto_page.withdraw .process_box .step {
  background-color: var(--iq-orange);
  color: var(--iq-white);
}

.howto_page.recurring .page-title {
  color: #fef08d;
}

.howto_page.recurring .page-title small {
  display: block;
  font-size: 0.6em;
  color: var(--iq-white);
}

.howto_page.play .process_box {
  background-color: var(--iq-white);
  border: solid 3px var(--iq-orange);
}

.howto_page.play .process_box .step {
  background-color: var(--iq-orange);
}

.howto_page .process_box img {
  display: flex;
  margin: 0 auto;
}

.page_framework {
  margin-bottom: 115px;
}

.page_framework .pagebanner p {
  font-size: 27px;

  margin: 0;
}

.page_framework .pagebanner img {
  margin-bottom: 70px;
}

.page_framework .pagebanner img#csr-mobile {
  display: none;
}

.page_framework .pagebanner_content {
  left: 48px;
}

.page_framework .pagebanner_content h1 {
  font-size: 50px;
  margin: 0;
}

.page_framework .pagebanner_content h1 p {
  font-size: 50px;
}

.framework_row {
  display: flex;
  flex-wrap: wrap;
}

.framework_row .framework-item:not(:last-child) {
  margin-bottom: 60px;
}

.framework-item__heading {
  display: flex;
  align-items: center;
}

.framework-item {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.framework-item .framework-item__heading h3 {
  margin: 0;
  font-size: 1.17em;
}

.framework-item .framework-item__img {
  width: 40px;
  margin-right: 10px;
}

.framework-item__text {
  max-width: 500px;
}

.framework-item__text p {
  font-size: 17px;
  color: var(--iq-darkgrey);
  line-height: 30px;
  margin: 0;
  padding-left: 50px;
  text-align: justify;
}

.page_collaborations {
  margin-bottom: 133px;
}

.page_collaborations .pagebanner_content {
  left: 60px;
}

.page_collaborations .pagebanner_content h1 {
  color: var(--iq-darkblack);
  max-width: 300px;
  font-size: 51px;
  margin: 0;
}

.page_collaborations .pagebanner_content h1 p {
  margin: 0;
}

.page_collaborations .pagebanner img#colab-mobile {
  display: none;
}

.collaborations_row {
  display: flex;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
}

.collaborations_row .collaborations-item {
  flex: 0 0 48%;
  max-width: 48%;
  padding: 10px;
}

.collaborations-item .collaborations-item__img {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  height: 222px;
  border-radius: 6px;
}

.page_projects {
  margin-bottom: 120px;
}

.page_projects .pagebanner #projects-mobile {
  display: none;
}

.page_projects .pagebanner_content h1 {
  max-width: 250px;
  font-size: 51px;
  margin: 0;
}

.page_projects .pagebanner_content h1 p {
  margin: 0;
}

.page_projects .pagebanner_content {
  left: 60px;
}

.projects_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.projects_row .projects-item {
  flex: 0 0 47%;
  max-width: 47%;
}

.projects_row .projects-item:not(:last-child) {
  margin-bottom: 60px;
}

.projects-item__img {
  padding: 20px;
  background-color: var(--iq-mildgrey);
  border-radius: 8px;
  margin-bottom: 30px;
}

.projects-item__img img {
  width: 100%;
}

.projects-item p {
  font-size: 17px;
  line-height: 30px;
  color: var(--iq-darkgrey);
  margin: 0;
  text-align: justify;
}

.page_our-initiatives .boxes .boxes__content {
  display: flex;
  justify-content: space-between;
}

.page_our-initiatives .pageoffers .pageoffers_box {
  background-color: var(--iq-white);
  border-style: solid;
  border-width: 3px;
  border-color: var(--iq-orange);
  width: 23.5%;
  margin-left: 1%;
  margin-right: 1%;
}

.page_our-initiatives .pageoffers .pageoffers_box:nth-child(1) {
  margin-left: 0;
}

.page_our-initiatives .pageoffers .pageoffers_box:nth-child(4) {
  margin-right: 0;
}

.page-contact .social_list li img {
  width: 38px;
  margin: 0 auto;
}

.map {
  width: 100%;
  height: 300px;
  margin: 0;
  max-width: 100%;
}

.collaborations_row {
  display: flex;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
}

.collaborations_row .collaborations-item {
  flex: 0 0 48%;
  max-width: 48%;
  padding: 10px;
}

.collaborations-item .collaborations-item__img {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  height: 222px;
  border-radius: 6px;
}

.pagination {
  margin-top: 16px;
}

.pagination ul {
  padding-left: 0;
  display: flex;
  margin: 0;
  justify-content: center;
}

.pagination ul li {
  list-style-type: none;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.pagination ul li:not(:last-child) {
  margin-right: 7px;
}

.pagination ul li a {
  font-size: 12px;
  font-family: Product-Sans-Bold !important;
  color: var(--iq-darkblack);
  padding: 11px 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--iq-orange);
  background-color: #f6f6f6;
  padding: 0;
  cursor: pointer;
}

.pagination ul li a:hover {
  border-color: var(--iq-orange);
}

.pagination ul li.active a {
  background-color: var(--iq-orange);
  font-family: Product-Sans-Bold;
  color: var(--iq-white);
  border-color: var(--iq-orange);
}

.pagination ul li a i {
  color: var(--iq-darkblack);
  margin-bottom: 5px;
}

.pagination .disabled {
  opacity: 0.5;
  pointer-events: none;
  display: none !important;
  font-family: Product-Sans-Regular;
}
.space-y-2 p {
  line-height: 2.5rem;
}
@media (max-width: 1228px) {
  .winners .winners_winner img {
    width: 250px;
  }

  .btn--terciary p {
    font-size: 17px;
    text-align: center;
  }
}

@media (max-width: 1130px) {
  .videos_bg {
    padding: 60px 0;
  }
}

@media (max-width: 1085px) {
  .page_community .pagebanner h1 {
    font-size: 70px;
    top: 36%;
  }

  .page_our-initiatives .pagebanner_content h1 p {
    font-size: 50px;
  }

  .page_play-responsibly .pagebanner_content {
    top: 14%;
  }

  .collaborations_row .collaborations-item {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .page_collaborations .pagebanner_content h1 {
    font-size: 35px;
  }
}

@media (max-width: 992px) {
  .page-faq,
  .page-legal {
    margin-bottom: 70px;
  }

  .howto_page.credit .pagebanner_content {
    top: 39%;
  }

  .page_community .pagebanner h1 {
    font-size: 61px;
    top: 36%;
    left: 3%;
  }

  .page_winners .pagebanner_content h2 p,
  .page_winners h1.page-title {
    font-size: 80px;
  }

  .page_play-responsibly .pagebanner_content h2 p {
    font-size: 35px;
    max-width: 200px;
  }

  .page_play-responsibly .pagebanner_content p {
    font-size: 18px;
  }

  .page_play-responsibly .pagebanner_content {
    top: 17%;
  }

  .page_play-responsibly {
    margin-bottom: 80px;
  }

  .news_article {
    margin-bottom: 40px;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    align-items: flex-start;
  }

  .news_article--wrapper {
    align-items: flex-start;
    text-align: left;
    height: unset;
  }

  .news_article p {
    font-size: 14px;
  }

  .news_article a {
    margin: 8px 0 20px;
    text-align: left;
  }

  .news_article--footer {
    flex-direction: column;
  }

  .news_article--footer a:first-child {
    text-align: center;
  }

  .news_article--footer a:last-child {
    justify-content: center;
    margin-left: 0;
  }

  .page_fraud-awareness .pagebanner_content {
    left: 25px;
  }

  .page_fraud-awareness .pagebanner_content h1 p {
    font-size: 40px;
  }

  .page_liveloto-draw .pagebanner_content h2 p,
  .page_liveloto-draw .pagebanner_content h1 p {
    font-size: 40px;
  }

  .page_liveloto-draw .pagebanner_content p {
    font-size: 21px;
  }

  .page_liveloto-draw .pagebanner_content {
    top: 33%;
  }

  .page_our-initiatives .pagebanner_content h1 p {
    font-size: 38px;
    margin-bottom: 8px;
  }

  .page_about-us .pagebanner_content h2 p {
    font-size: 17px;
  }

  .page_about-us .pagebanner_content {
    margin-left: 0;
    top: 27%;
  }

  .videos_wrap {
    flex-direction: column;
  }

  .videos_wrap--content {
    width: 100%;
  }

  .videos_wrap .video iframe {
    display: block;
    margin: 0 auto;
  }

  .videos_wrap:not(:last-child) {
    margin: unset;
  }

  .videos_wrap--content {
    margin-bottom: 35px;
  }

  .videos_wrap .video_footer h3 {
    font-size: 17px;
    margin: 20px 0 7px 0;
  }

  .videos_wrap .video_footer p {
    font-size: 14px;
    margin: 0 auto;
  }

  .video iframe {
    width: 80%;
  }

  .winners .winners_winner img {
    width: 180px;
  }

  .page_draw .pagebanner_content {
    margin-top: -45px;
  }

  .page_draw .pagebanner_content h1 p {
    font-size: 75px;
  }

  .page_draw .draw_footer .draw_footer-box a {
    height: 90px !important;
  }

  .howto_page .pagebanner_content h2 p {
    font-size: 17px;
  }

  .howto_page .pagebanner_content p strong {
    font-size: 35px;
  }

  .howto_page .pagebanner_content {
    left: 33px;
    top: 32%;
  }

  .howto_page .pagebanner_content h2 p {
    font-size: 20px;
  }

  .video_date {
    text-align: center;
  }

  .page_about-us .pagebanner_content h2 p,
  h1.page-title {
    font-size: 33px;
  }

  .page_about-us .pagebanner_subheading p {
    font-size: 17px;
  }

  .news_detail .share_tab {
    margin-top: 30px;
  }

  .winners .winners_winner h3 {
    max-width: 182px;
    font-size: 18px;
    line-height: 25px;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .winners .winners_winner p {
    font-size: 14px;
    margin-top: 4px;
  }

  .framework-item {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .page_our-initiatives .pageoffers {
    display: flex;
    justify-content: unset;
    flex-wrap: wrap;
  }

  .page_our-initiatives .pageoffers .pageoffers_box {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 50%;
    max-width: 48%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 4%;
    margin-right: 0px;
  }

  .page_our-initiatives .pageoffers .pageoffers_box:not(:last-child) {
    margin-bottom: 4%;
  }

  .page_our-initiatives .pageoffers .pageoffers_box:nth-child(3) {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .pagination ul li {
    width: 30px;
    height: 35px;
  }

  .news_article {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .news_article {
    align-items: flex-start;
  }

  .videos_wrap--content {
    margin-right: 0;
  }

  .winners .pageheader,
  .pageheader p {
    margin: 2px 0 20px 0;
  }

  .page_latest-news {
    margin: 10px 0 200px 0;
  }

  .winners {
    flex-direction: column;
  }
  .accordeon_box {
    padding: 15px 15px 15px 15px;
  }
  .page-faq h2,
  .page-faq h1 {
    margin: 30px 0 30px 0;
  }

  .accordeon p,
  h6 {
    font-size: 14px;
  }

  .page_community .pagebanner h1 {
    font-size: 43px;
  }

  .page_winners .pagebanner_content h2 p,
  .page_winners h1.page-title {
    font-size: 37px;
  }

  .textblock strong {
    margin-bottom: 5px;
  }

  #play_responsibly-desktop {
    display: none;
  }

  #play_responsibly-mobile {
    display: block;
  }

  #buy-credit-desktop {
    display: none;
  }

  #buy-credit-mobile {
    display: block;
  }

  #how-play-mobile {
    display: block;
  }

  #how-play-desktop {
    display: none;
  }

  .howto_page.play .pagebanner_content {
    top: 19%;
  }

  .news_row {
    flex-direction: column;
  }

  .news_article {
    max-width: 100%;
    margin: 0 0 25px 0 !important;
  }

  #fraud-awareness-desktop {
    display: none;
  }

  #fraud-awareness-mobile {
    display: block;
  }

  .page_fraud-awareness .pagebanner_content {
    top: 30px;
    left: 18px;
  }

  .page_play-responsibly .pagebanner_content {
    top: 5%;
  }

  .page_fraud-awareness .pagebanner_content h1 p {
    font-size: 37px;
  }

  #live_draw-desktop {
    display: none;
  }

  #live_draw-mobile {
    display: block;
  }

  .page_liveloto-draw .pagebanner_content {
    top: 14%;
    left: 27px;
  }

  .page_our-initiatives .pagebanner_content {
    top: 9%;
  }

  .page_our-initiatives {
    margin-bottom: 75px;
  }

  .news_row {
    margin-top: 0;
  }

  .page-contact .page-contact--header img {
    display: none;
  }

  .contact_boxes {
    flex-direction: column;
  }

  .contact_boxes .contactbox {
    width: 100%;
    justify-content: flex-start;
    padding-left: 30px;
    width: unset;
  }

  .contact_boxes .contactbox:nth-child(1) {
    margin: unset;
  }

  .contact_boxes .contactbox:nth-child(2) {
    margin-top: 10px;
  }

  .social_list {
    clear: both;
    display: table;
    width: 100%;
  }

  .page-contact .social_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .page-contact .social_list li {
    flex: 0 0 47%;
    width: 47%;
  }

  .page-contact .social_list li {
    margin-bottom: 15px;
  }

  .page-contact .social_list li:nth-child(2n) {
    margin-right: 0px !important;
  }

  #about_mobile {
    display: block;
  }

  #about_desktop {
    display: none;
  }

  #videos_desktop {
    display: none;
  }

  .page_about-us .pagebanner_content {
    top: 8%;
  }

  .page_about-us .videos_heading_div {
    padding: 20px 0px;
  }

  .page_about-us .videos_heading {
    font-size: 18px;
  }

  .page-contact .social_list li:not(:last-child) {
    margin-right: 15px;
  }

  .page_winners-stories {
    margin: 0px 10px 25px 10px;
  }

  .page_winners-stories .pageheader {
    margin-bottom: 25px;
  }

  .videos_bg {
    padding: 16px 0;
  }

  .video_date {
    text-align: center;
  }

  .video_date:first-of-type {
    margin-top: 25px;
  }

  .winners_row {
    flex-direction: column;
  }

  .winners .winners_winner img {
    width: 300px;
  }

  .winners_winner {
    margin-bottom: 35px;
  }

  .winners .winners_winner {
    margin-right: 0;
  }

  .page_draw .pagebanner {
    display: none;
  }

  .page_draw .pagebanner_content h1 {
    font-size: unset;
  }

  .page_draw .pagebanner_content {
    position: relative;
    font-size: 37px;
    left: 0;
  }

  .page_draw .pagebanner_content h1 p {
    font-size: 37px;
  }

  #draw_boxes_desktop {
    display: none;
  }

  .draw_mobile_slider {
    display: flex;
  }

  .page_draw .pagebanner_content {
    margin-top: 0;
    margin-left: 0;
  }

  .textblock {
    margin: 0px 0 20px 0;
  }

  #withdraw_mobile {
    display: block;
  }

  #withdraw_desktop {
    display: none;
  }

  .howto_page .pagebanner_content h2 p {
    margin-top: 0;
    font-size: 17px;
  }

  .howto_page .pagebanner_content {
    left: 13px;
    top: 14%;
  }

  .howto_page .pagebanner_content p strong {
    font-size: 29px;
  }

  .howto_page.credit .pagebanner_content {
    top: 15%;
  }

  .page_winners-stories .pageheader p {
    font-size: 20px;
  }

  .page_about-us .videos_wrapper--slide {
    margin-bottom: 15px;
  }

  .page_about-us .videos_wrapper--slide {
    margin-right: 0 !important;
  }

  .videos_wrapper--btn {
    max-width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    background-color: var(--iq-yellow);
    transition: 0.2s ease-in-out;
    display: none;
  }

  .videos_wrapper--btn:hover {
    border: 2px solid var(--iq-black);
  }

  .videos_wrapper--btn img {
    margin-left: 15px;
    position: relative;
    top: -2px;
  }

  .news_detail .textwrapper {
    padding: 0 15px;
  }
  h2.page-sub-title,
  .textblock strong,
  .textblock h2,
  .textblock h1,
  .page-faq .pageheader p,
  .pageheader,
  .pageheader p,
  .videos_heading p strong {
    font-size: 20px !important;
  }
  .textblock p,
  .news_detail .textblock strong {
    font-size: 15px !important;
    line-height: 27px;
  }
  .pagebanner {
    margin: 20px 0;
  }

  .news_detail .pageheader p {
    font-size: 21px;
    line-height: 36px;
    margin-top: 40px;
  }

  .page_about-us .videos_wrapper .about_video {
    width: 320px !important;
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }

  .page_about-us .videos {
    padding: 30px 15px 45px 20px;
  }

  .page-contact .community-guidelines {
    margin-top: 20px;
  }

  .page_framework .pagebanner img {
    margin-bottom: 35px;
  }

  .page_framework .pagebanner_content h1 {
    font-size: 40px;
  }

  .page_framework .pagebanner_content h1 p {
    font-size: 40px;
  }

  .page_framework .pagebanner_content {
    left: 25px;
  }

  .page_projects .pagebanner_content h1 {
    font-size: 40px;
  }

  .page_projects .pagebanner_content {
    left: 30px;
    top: 30%;
  }

  .projects_row .projects-item {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .page_our-initiatives
    .boxes
    .boxes__content
    .boxes__box:nth-child(2)
    .boxes__box--img {
    padding: 0;
  }

  .page_our-initiatives .pageoffers .pageoffers_box {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    margin-left: 0;
  }

  .page_our-initiatives .pageoffers .pageoffers_box img {
    margin-bottom: 20px;
  }

  .page_our-initiatives .pageoffers {
    flex-direction: column;
  }

  .page_our-initiatives .textwrapper {
    margin-bottom: 0;
  }

  .page_winners .pageoffers {
    display: block;
  }

  .page_winners .pageoffers_box {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .page_latest-news {
    margin: 10px 0;
  }
  .pagination ul li {
    width: 27px;
    height: 27px;
  }
  .pagination ul li a {
    font-size: 11px;
  }
  .news_article {
    margin-bottom: 25px;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  h2.page-sub-title,
  .textblock strong,
  .textblock h2,
  .textblock h1,
  .page-faq .pageheader p,
  .pageheader,
  .pageheader p,
  .videos_heading p strong {
    font-size: 18px !important;
  }
  .pageheader {
    font-size: 20px;
  }

  #pageoffers_mobile {
    display: flex;
    flex-direction: column;
    margin: 30px 0 40px 0;
  }

  .page_framework .pagebanner img#csr-mobile,
  .page_collaborations .pagebanner img#colab-mobile,
  .page_projects .pagebanner img#projects-mobile {
    display: block;
  }

  .page_winners .pageoffers,
  #pageoffers_desktop,
  .page_community .pagebanner,
  .page_winners .pagebanner img,
  .page_framework .pagebanner img#csr-desktop,
  .page_collaborations .pagebanner img#colab-desktop,
  .page_projects .pagebanner img#projects-desktop {
    display: none;
  }

  .page_community .pagebanner h1 {
    position: relative;
    font-size: 37px;
    top: 0;
    left: 0;
  }

  .page_community {
    margin-bottom: 55px;
  }

  .page_winners .pagebanner h2,
  .page_winners h1.page-title {
    font-size: 37px;
  }

  .page_winners .pagebanner_content {
    position: relative;
    top: 0;
    left: 0;
  }

  .page_winners {
    margin-bottom: 60px;
  }

  .page_play-responsibly .pagebanner_content {
    top: 5%;
  }

  .pagebanner_content {
    left: 17px;
  }

  .pagebanner_content p strong {
    line-height: 23px;
  }

  .page_liveloto-draw .pagebanner_content h2 p,
  .page_liveloto-draw .pagebanner_content h1 p {
    font-size: 35px;
  }

  .page_liveloto-draw .pagebanner_content p {
    font-size: 18px;
  }

  .textblock h5 {
    font-size: 20px;
  }

  .page_our-initiatives .pagebanner_content h1 p {
    font-size: 31px;
  }

  .page-contact {
    margin: 50px auto 70px;
  }

  .videos_bg {
    margin-bottom: 70px;
  }

  .howto_page .pagebanner_subheading {
    max-width: 250px;
  }

  .howto_page .pagebanner_subheading p {
    margin-top: 5px;
    line-height: 26px;
  }

  .page_winners-stories .pageheader p {
    font-size: 27px;
  }

  .page_about-us .videos {
    padding: 10px 10px 45px 10px;
  }

  .page_about-us .videos_wrapper .about_video {
    width: 255px !important;
  }

  .howto_video_popup.active {
    padding: 25px 15px 15px 15px;
    width: 280px;
    height: 260px;
  }

  .howto_video_popup.active img {
    top: -12px;
    left: 0px;
  }

  .howto_video_popup.active iframe {
    height: 200px;
  }

  .framework-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .page_framework .pagebanner_content {
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
    text-align: center;
    top: 20%;
  }

  .page_collaborations .pagebanner_content {
    left: 29px;
    top: 10%;
  }

  .page_projects .pagebanner_content {
    top: 10%;
  }

  .page_projects .pagebanner_content h1 {
    font-size: 30px;
  }

  .page_projects .projects-item h3 {
    font-size: 20px;
  }

  .page_projects .projects-item p {
    font-size: 14px;
    line-height: 27px;
  }

  .projects_row .projects-item:not(:last-child) {
    margin-bottom: 30px;
  }

  .page_projects {
    margin-bottom: 60px;
  }

  .page-contact .community-guidelines {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .page_play-responsibly .pagebanner_content h2 p {
    font-size: 28px;
    max-width: 100%;
  }

  .page_play-responsibly .pagebanner_content p {
    font-size: 17px;
  }

  .page-contact .social_list p {
    font-size: 14px;
  }

  .contact_boxes .contactbox {
    flex-direction: column;
    padding: 20px 0;
  }

  .contact_boxes .contactbox img {
    margin: 0 0 10px 0;
  }

  .page-contact .contact_boxes .contactbox p,
  a {
    font-size: 17px;
    text-align: center;
    line-height: 23px;
  }

  .video_date {
    font-size: 14px;
  }

  .howto_page .pagebanner_content h2 p {
    max-width: 260px;
    line-height: 26px;
  }

  .padded {
    padding: 0 10px;
  }

  .howto_page.credit .pagebanner_content {
    max-width: 160px;
  }

  .page_about-us .videos_wrapper .about_video {
    width: 225px !important;
  }

  .page_framework .pagebanner_content h1 {
    font-size: 30px;
  }

  .page_framework .pagebanner_content h1 p {
    font-size: 30px;
    max-width: 100%;
    line-height: initial;
    margin: initial;
    text-align: center;
  }

  .page_framework .pagebanner p {
    font-size: 17px;
    line-height: 26px;
    max-width: 260px;
    margin: 0 auto;
    text-align: center;
  }

  .page_framework .pagebanner img {
    margin-bottom: 20px;
  }

  .page_our-initiatives .boxes .boxes__content .boxes__box .boxes__box--img a {
    font-size: 17px;
    padding: 10px;
  }

  .page_our-initiatives .boxes .boxes__content .boxes__box:not(:last-child) {
    margin-bottom: 20px;
  }

  .news_article img {
    width: auto;
  }
  .pagination ul li {
    width: 24px;
    height: 24px;
  }
}

.pagination_div {
  display: flex;
  text-align: center;
  padding: 0px 24px;
  justify-content: center;
}
.ffv span,
.news_article .ln-date.reqular-iq {
  font-family: Product-Sans-Regular !important;
}
.accordeon table,
.accordeon td,
.accordeon th {
  border: 1px solid black;
}
@media (max-width: 992px) {
  .pagebanner {
    margin: 20px 0;
  }
}

@media (min-width: 576px) {
  .pagination_div {
    justify-content: center;
  }
}
@media (min-width: 767px) {
  .space-y-0
    > .bg-white.container:first-child
    .space-y-2
    > .new-space:first-child,
  .space-y-4
    > .bg-white.container:first-child
    .space-y-2
    > .new-space:first-child {
    margin-top: 20px;
  }
}

.row_padding {
  padding: 10px 0px;
}

.mobile_overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s;
}

@media (max-width: 768px) {
  html[lang="ar"] h6.username {
    margin-right: 20px !important;
  }
}

html[lang="ar"] ol {
  list-style: disc !important;
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  height: 50%;
  background: #000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
}
